import { Routes, Route } from "react-router-dom";
import './App.css';
import Header from "./components/Header";
import Homepage from "./Pages/Homepage";
import CoinPage from "./Pages/Coinepage";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  App: {
    backgroundColor: "#14161a",
    color: "white",
    minHeight: "100vh"
  }
});
function App() {

  const classes = useStyle();
  return (

    <div className={classes.App}>

      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} exact />
        <Route path="/coins/:id" element={<CoinPage />} />
      </Routes>

    </div>
  )
}

export default App;
